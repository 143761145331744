import {
  Box, ButtonBase, Card,
  CardContent, Divider, IconButton, Tooltip, Typography
} from '@mui/material';
import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';

import { useDispatch } from 'react-redux';
import { setSiteDetails, setSitePagesState, setUpdatingData } from '../../redux/admin/adminSlice';


import DataService from '../../services/dataService';

// icons
import AddIcon from '@mui/icons-material/Add';
import { siteMetadata } from '../../../gatsby-config';

const useFetching = (someFetchActionCreator, data) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch( someFetchActionCreator( data ) )
  }, []);

}

const Pages = ({ data, location, pageContext }) => {

    const dispatch = useDispatch();

    const [OverlayDisplay, setOverlayDisplay] = React.useState(null);
    const [sitePages, setSitePages] = React.useState(data.datoCmsWebsite.pages);
    const [templatePages, setTemplatePages] = React.useState([]);
    const [normalPages, setNormalPages] = React.useState([]);


    useFetching(setSitePagesState, { sitePages: data.datoCmsWebsite.pages.map( page => page.name ) });
    useFetching(setSiteDetails, { site: { name: data.datoCmsWebsite.name, id: data.datoCmsWebsite.id } });

    React.useEffect(() => {
      if (location.state !== null) {
        if (typeof location.state.from !== 'undefined') {
          switch(location.state.from) {
            case 'sections':

              dispatch(setUpdatingData({ updatingData: true }));

              DataService.getAllRecords(siteMetadata.datoCmsModelIds.Page, data.datoCmsWebsite.id)
                .then( pages => {
                  setTemplatePages(pages.filter( page => page.isTemplate == true ));
                  setNormalPages(pages.filter( page => page.isTemplate == false ));
                  dispatch(setUpdatingData({ updatingData: false }));
                }
              )
          }
  
          return;
        }
      }

      setTemplatePages(data.datoCmsWebsite.pages.filter( page => page.isTemplate == true ));
      setNormalPages(data.datoCmsWebsite.pages.filter( page => page.isTemplate == false ))
    }, []);


    const sortAndSetPages = (pages) => {
      setTemplatePages(data.datoCmsWebsite.pages.filter( page => page.isTemplate == true ));
      setNormalPages(data.datoCmsWebsite.pages.filter( page => page.isTemplate == false ))
    }


    const updatePages = (pages) => {
      
      sortAndSetPages(pages);
      setOverlayDisplay(null);
    }


    const updatePageOrder = async (result) => {

      const fromSection = sitePages[result.source.index];
      
      let pageListCopy = sitePages;
      pageListCopy.splice(result.source.index, 1);
      pageListCopy.splice(result.destination.index, 0, fromSection);

      if (pageListCopy.length > 0) {
        const pageListIds = pageListCopy.map( page => page.id.split('-')[1] );
        DataService.updateRecord(data.datoCmsWebsite.id.split('-')[1], {
            pages: pageListIds
        }).then( res => {
            setSitePages(pageListCopy);
        });
      }

    }


    const openOverlayTool = () => {
      let overlay =  React.lazy(() => import('../../components/overlay/overlay').catch(() => <div style={{ display: 'none' }}></div>))
      setOverlayDisplay(overlay);
    }


    const closeOverlayTool = () => setOverlayDisplay(null);


    return (
        <Layout>
          {
            OverlayDisplay != null && (
              <React.Suspense fallback={<div style={{ display: 'none' }}></div>}>
                <OverlayDisplay 
                  type="pages" 
                  closeOverlayTool={closeOverlayTool} 
                  siteId={data.datoCmsWebsite.id} 
                  pageId={null}
                  iterables={data.datoCmsWebsite.pages} 
                  sectionIds={null}
                  updatePages={updatePages}
                />
              </React.Suspense>
            )
          }
            <div style={{ backgroundColor: 'whitesmoke', height: '100%', padding: 10 }}>

                <Tooltip 
                      title={"Add Page"} 
                      placement='left-start'
                      className="hi"
                      style={{
                          position: 'fixed',
                          bottom: 20,
                          right: 20,
                          backgroundColor: '#45425a',
                          width: 100,
                          height: 100,
                          WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                          boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)' 
                      }}
                  >
                      <IconButton 
                          onClick={() => openOverlayTool() }
                      >
                          <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'white' }} />
                      </IconButton>
                  </Tooltip>

                  {/* <Button
                    onClick={() => DataService.getAllDataFromDatoCms() }
                  >
                    Get all data from datoCMS
                  </Button> */}

                {/* <Divider style={{ marginLeft: '4rem' }} /> */}

                {
                  templatePages.length > 0 && (
                    <div className='flex flex-col justify-between p-10 rounded-sm bg-slate-300 border border-slate-500 shadow-inner'>

                      <h2 className="text-2xl inline font-bold mb-4 text-center" style={{ color: '#45425a' }}>Templates</h2>

                      <Divider />

                      <div className="flex justify-center mt-4" style={{ display: 'flex mt-4 ml-9' }}>
                      {
                        templatePages.map( (page, index) => (
                          <ButtonBase
                            key={index}
                            className="m-4"
                            style={{ margin: '1rem' }}
                            onClick={() => {
                              navigate('/admin/sections', {
                                state: {
                                  PageId: page.id,
                                  sitePages: data.datoCmsWebsite.pages.map( page => page.name ),
                                  templates: data.datoCmsWebsite.pages.filter( page => page.isTemplate ),
                                  currentTemplate: page.template
                                }
                            })}}
                          >
                            <Box style={{ margin: 0 }}>
                              <Card className="p-10" key={1}>
                                <CardContent
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 30,
                                    paddingLeft: 20
                                  }}
                                >
                                  <Typography 
                                    key={1}
                                    variant="h5" 
                                    component="div"
                                    style={{
                                      flex: 1
                                    }}
                                  >
                                    {page.name}
                                  </Typography>
                                  <Typography key={2} sx={{ mb: 1.5 }} color="text.secondary">
                                    {
                                      page.sections.length > 0 ? (
                                        <React.Fragment>
                                          {page.sections.length} sections
                                        </React.Fragment>
                                      )
                                      :
                                      (
                                        <React.Fragment>
                                          Add Sections
                                        </React.Fragment>
                                      )
                                    }
                                  </Typography>
                                  <Typography key={3} sx={{ mb: 1.5 }} color="text.secondary">
                                    {page.url}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </ButtonBase>
                        ))
                      }
                      </div>
                    </div>
                  )
                }
                <div className="h-10" />
                <div className='h-10' />
                {
                  normalPages.length > 0 && (
                    <div className='flex flex-col justify-evenly p-10 rounded-sm bg-slate-300 border border-slate-500 shadow-inner'>

                      <h2 className="text-2xl inline font-bold mb-4 text-center" style={{ color: '#45425a' }}>Pages</h2>

                      <Divider />

                      <div className="flex flex-wrap mt-4 justify-center" style={{ display: 'flex mt-4 ml-9' }}>
                      {
                        normalPages.map( (page, index) => (
                          <ButtonBase
                            key={index}
                            className="m-4"
                            style={{ margin: '1rem' }}
                            onClick={() => {
                              navigate('/admin/sections', {
                                state: {
                                  PageId: page.id,
                                  sitePages: data.datoCmsWebsite.pages.map( page => page.name ),
                                  templates: data.datoCmsWebsite.pages.filter( page => page.isTemplate ),
                                  currentTemplate: page.template
                                }
                            })}}
                          >
                            <Box style={{ margin: 0 }}>
                              <Card className="p-10" key={1}>
                                <CardContent
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 30,
                                    paddingLeft: 20
                                  }}
                                >
                                  <Typography 
                                    key={1}
                                    variant="h5" 
                                    component="div"
                                    style={{
                                      flex: 1
                                    }}
                                  >
                                    {page.name}
                                  </Typography>
                                  <Typography key={2} sx={{ mb: 1.5 }} color="text.secondary">
                                    {
                                      page.sections.length > 0 ? (
                                        <React.Fragment>
                                          {page.sections.length} sections
                                        </React.Fragment>
                                      )
                                      :
                                      (
                                        <React.Fragment>
                                          Add Sections
                                        </React.Fragment>
                                      )
                                    }
                                  </Typography>
                                  <Typography key={3} sx={{ mb: 1.5 }} color="text.secondary">
                                    {page.url}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </ButtonBase>
                        ))
                      }
                      </div>
                    </div>
                  )
                }
            </div>
        </Layout>
    )
}

export const data = graphql`
  query allSitePagesQuery($sitename: String) {
    datoCmsWebsite(name: {eq: $sitename}) { 
      id
      name
      pages {
        id
        name
        isTemplate
        template {
          id
          name
        }
        sections {
          id
          name
          posts {
            id
            title
            content {
              value
            }
            published
            posttype
          }
          settings
        }
      }
    }
  }
`

export default Pages;
